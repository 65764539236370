<template>
  <el-card id="niche">
    <header>
      <h2 style="margin-bottom: 20px">
        公司问题记录<i class="el-icon-s-help"></i>
      </h2>
      <div class="top-box">
        <div class="select-item">
          <span>处理状态</span>
          <el-select v-model="screenForm.yes_answer" clearable placeholder="按处理状态筛选">
            <el-option v-for="item in JDZToptions" :key="item.status" :label="item.table" :value="item.status">
            </el-option>
          </el-select>
        </div>
        <div class="select-item">
          <span>查询公司</span>
          <el-select v-model="firmValue" placeholder="选择查询公司" size="mini" filterable @change="getFirmVal">
            <el-option v-for="item in firmList" :key="item.id" :label="item.realname" :value="item.id">
            </el-option>
          </el-select>
        </div>
        <!-- <div class="select-item">
          <span>选择跟进人</span>
          <el-select
            v-model="screenForm.userId"
            filterable
            clearable
            placeholder="按跟进人筛选"
          >
            <el-option
              v-for="item in staffList"
              :key="item.user_id"
              :label="item.realname"
              :value="item.user_id"
            >
            </el-option>
          </el-select>
        </div>
        <div class="select-item">
          <span>选择客户</span>
          <el-select
            v-model="screenForm.clentId"
            filterable
            clearable
            placeholder="按客户筛选"
          >
            <el-option
              v-for="item in KHoptions"
              :key="item.id"
              :label="item.customerPhone"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </div> -->
        <!-- <div class="select-item">
          <span>公司名称</span>
          <el-input v-model="screenForm.userName" placeholder="输入公司名称" clearable></el-input>
        </div> -->
        <div class="select-item">
          <span>创建日期</span>
          <el-date-picker v-model="createTimeDate" type="datetimerange" range-separator="-" start-placeholder="开始日期"
            end-placeholder="结束日期" align="center" size="mini">
          </el-date-picker>
        </div>
      </div>
      <div class="search">
        <div class="left">
          <div class="left-wrap">
            <el-button type="primary" icon="el-icon-circle-plus-outline" @click="addNiche" size="mini"
              class="searchBtn">新增问题</el-button>
          </div>
        </div>
        <div class="right">
          <el-button type="success" icon="el-icon-search" @click="query" size="mini">查询
          </el-button>
          <el-button type="success" icon="el-icon-refresh" @click="reset" size="mini">重置
          </el-button>
        </div>
      </div>
    </header>
    <hr>
    <main>
      <div class="company-box" v-if="tableData.length != 0">
        <div class="company-list" style="margin-bottom: 20px" v-for="v in tableData" :key="v.id">
          <div class="company-title" style="
                font-size: 20px;
                background-color: #dbdfe2;
                padding: 16px 10px;
                box-sizing: border-box;
                border-radius: 5px;
              ">
            {{ v.userName }}
            <el-tag type="info" style="margin-left: 20px">创建时间
              {{ getYMDHMS("YYYY-mm-dd HH:MM:SS", v.created_time) }}</el-tag>
            <el-button type="success" size="mini" v-if="!(role == '客服' && v.answer)" style="margin-left: 10px"
              @click="updataCompany(v)">{{ v.answer ? "修改记录" : "添加记录" }}</el-button>
            <div class="company-content" style="font-size: 18px; font-weight: bold; margin-top: 10px">
              <div style="width: 60px">问 题：</div>
              <div style="margin-top: 10px; text-indent: 60px; line-height: 30px">
                {{ v.question || "空" }}
              </div>
            </div>
          </div>
          <div style="margin: 10px; font-size: 16px">
            <p>
              答案记录：
              <!-- <el-tag type="info" style="margin-left: 10px">记录时间
                {{ getYMDHMS("YYYY-mm-dd HH:MM:SS", v.answer_time) }}</el-tag> -->
            </p>
            <p style="
                  margin-top: 10px;
                  text-indent: 60px;
                  font-weight: bold;
                  line-height: 30px;
                ">
              {{ v.answer || "暂未处理" }}
            </p>
          </div>
          <hr />
        </div>
      </div>
      <el-empty v-else description="暂无问题记录"></el-empty>
      <!--分页  -->
      <div class="pagination">
        <el-pagination background layout="total, prev, pager, next,sizes,jumper" :current-page="pageIndex" :page-size="pageSize"
          :page-sizes="[10, 20, 50, 100, 200]" :total="pageTotal" @size-change="handleSizeChange"
          @current-change="handlePageChange">
        </el-pagination>
      </div>
    </main>

    <!-- 弹框 -->
    <el-dialog title="记录问题" :visible.sync="outerVisible" @close="closeDialog">
      <el-form ref="form" label-position="right" size="medium" :model="form" label-width="100px">
        <!-- 表单过多时使用下面这种布局 -->
        <el-row :gutter="20">
          <el-col :span="20">
            <el-form-item label="提问的公司">
              <el-select v-model="form.parentId" placeholder="选择提出问题的公司" size="mini" filterable @change="getFirmVal"
                :disabled="setText == '禁用'">
                <el-option v-for="item in firmList" :key="item.id" :label="item.realname" :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>

        <!-- <el-form-item :label="问题的内容">
          <el-input type="textarea" v-model="form.question" maxlength="1000" show-word-limit placeholder="输入问题内容"
            autosize></el-input>
        </el-form-item> -->

        <el-form-item :label="setText == '禁用' ? '答案记录' : '问题的内容'">
          <el-input type="textarea" v-model="form.question" maxlength="1000" show-word-limit
            :placeholder="setText == '禁用' ? '输入答案记录' : '输入问题内容'" autosize></el-input>
        </el-form-item>

        <el-form-item>
          <div class="btn-box">
            <el-button @click="closeDialog">取消</el-button>
            <el-button type="primary" @click="onSubmit">确认</el-button>
          </div>
        </el-form-item>
      </el-form>
    </el-dialog>
  </el-card>
</template>

<script>
import { ElMessage } from "@/assets/js/elementui-api";
let Message = new ElMessage();

import { select, add, deleteNiche, update } from "@/api/niche";
import { phoneList } from "@/api/phone";
import { userList1 } from "@/api/user";
import {
  customerQuestionList,
  customerQuestionAdd,
  customerQuestionUpdate,
} from "@/api/company_renew";

import { userOperatorsFindlist,userFindlist } from "@/api/user";
export default {
  data() {
    return {
      firmValue: null,
      firmList: [],
      JDZToptions: [
        {
          status: 1,
          table: "已处理",
        },
        {
          status: 2,
          table: "未处理",
        },
      ],
      createTimeDate: "",
      staffList: [],
      tableLoad: false, // 表格加载效果
      screenForm: {}, // 筛选表单
      KHoptions: [],
      JDoptions: [
        "初步沟通(10%)",
        "方案报价(50%)",
        "谈判协商(80%)",
        "赢单(100%)",
        "输单(0%)",
        "无效",
      ],
      setText: "",
      role: null,
      outerVisible: false,
      form: {},
      tableData: [], //表格内绑定的数据
      pageTotal: 0, //总条数
      pageIndex: 1, //当前在第几页
      pageSize: 10, //每页显示的数量
      page: 1, //第几页
      size: 10, //每页显示的数量
      dataLabel: [
        {
          label: "跟进人",
          name: "createUserName",
        },
        {
          label: "商机名称",
          name: "name",
        },
        {
          label: "客户名称",
          name: "clentName",
        },
        {
          label: "商机备注",
          name: "remarke",
        },
        {
          label: "销售阶段",
          name: "shangjiStage",
        },
        {
          label: "预计成交金额",
          name: "money",
        },
        {
          label: "预计成交日期",
          name: "predictMakeTime",
        },
      ],
    };
  },
  async created() {
    await this.getList();
    await this.operatorsList();
    this.role = localStorage.getItem("role");
    // await this.getLntentionUser();
    // await this.getAllStaff();
  },
  methods: {
    updataCompany(value) {
      this.setText = "禁用";
      this.outerVisible = true;
      this.form = {
        parentId: value.userName,
        name: value.id,
        question: value.answer,
      };
      // parentId
      console.log("value", value);
      // question
    },
    // 获取下拉框选中值
    getFirmVal(val) {
      // this.firmValue =value
    },
    // 查公司
    operatorsList() {
      let params = {
        page: 1,
        size: 499,
        parentId: 0,
      };
      let res
      if (localStorage.getItem("roleName") == '管理员') {
        res = userFindlist(params);
      } else {
        res = userOperatorsFindlist(params);
      }

      res.then((res) => {
        this.firmList = res.data.data;
      });
    },
    // 转换日期
    getYMDHMS(format, date) {
      if (!date) {
        return "";
      }
      date = new Date(date);
      const dataItem = {
        "Y+": date.getFullYear().toString(),
        "m+": (date.getMonth() + 1).toString(),
        "d+": date.getDate().toString(),
        "H+": date.getHours().toString(),
        "M+": date.getMinutes().toString(),
        "S+": date.getSeconds().toString(),
      };
      Object.keys(dataItem).forEach((item) => {
        const ret = new RegExp(`(${item})`).exec(format);
        if (ret) {
          format = format.replace(
            ret[1],
            ret[1].length === 1
              ? dataItem[item]
              : dataItem[item].padStart(ret[1].length, "0")
          );
        }
      });
      return format;
    },
    // 查询
    query() {
      this.getList();
    },
    // 获取所有员工
    async getAllStaff() {
      let res = await userList1({});
      console.log("获取所有员工", res.data.data);
      if (res.data.statusCode == "00000") {
        this.staffList = res.data.data;
      }
    },
    // 重置
    reset() {
      this.page = 1;
      this.size = 10;
      this.firmValue = null;
      this.screenForm = {};
      this.createTimeDate = "";
      this.getList();
    },
    // 单个删除
    delNiche(row) {
      this.$confirm(
        `是否要删除客户 "${row.clentName}" 的该商机信息?`,
        "删除商机",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      )
        .then(async () => {
          let params = {
            id: row.id,
          };
          let res = await deleteNiche(params);
          if (res.data.statusCode == "00000") {
            await this.closeDialog();
            Message.success(res.data.message);
          } else {
            Message.error(res.data.message);
          }
        })
        .catch(() => {
          Message.info("已取消删除");
        });
    },
    // 手动验证方法
    verification1() {
      if (!this.form.clentName) {
        Message.warning("请选择客户");
        return false;
      } else {
        return true;
      }
    },
    // 新增或修改的提交
    async onSubmit() {
      const loading = await this.$loading({
        lock: true,
        text: "提交中,请稍等...",
      });

      let params = {
        ...this.form,
      };
      let res;
      if (this.setText == "禁用") {
        params = { id: this.form.name, answer: this.form.question };
        res = await customerQuestionUpdate(params);
      } else {
        res = await customerQuestionAdd(params);
      }
      console.log("this.form", res);

      if (res.data.statusCode == "00000") {
        Message.success(res.data.message);
        loading.close();
        this.closeDialog();
      } else {
        Message.error(res.data.message);
        loading.close();
      }
    },
    // 双击单行查看详情
    dblclick(row) {
      this.form = row;
      this.setText = "查看";
      this.outerVisible = true;
    },
    // 获取所有意向客户
    async getLntentionUser() {
      let params = {
        bsf: 1,
        page: 1,
        size: 5000,
      };
      let res = await phoneList(params);
      this.KHoptions = res.data.data;
    },
    // 分页
    handlePageChange(val) {
      this.page = val;
      this.getList();
    },
    handleSizeChange(val) {
      this.size = val;
      this.getList();
    },
    // 编辑商机
    editNiche(row) {
      console.log("row", row);
      this.form = row;
      this.setText = "编辑";
      this.outerVisible = true;
    },
    // 新增商机
    addNiche() {
      this.setText = "新增";
      this.outerVisible = true;
    },
    // 关闭弹框
    closeDialog() {
      this.form = {};
      this.outerVisible = false;
      this.getList();
    },
    // 获取表格数据
    async getList() {
      // this.tableLoad = true;
      let params = {
        page: this.page,
        size: this.size,
        ...this.screenForm,
      };
      if (this.createTimeDate) {
        params.sta = this.getYMDHMS(
          "YYYY-mm-dd HH:MM:SS",
          this.createTimeDate[0]
        );
        params.end = this.getYMDHMS(
          "YYYY-mm-dd HH:MM:SS",
          this.createTimeDate[1]
        );
      }
      if (this.firmValue) {
        params.parentId = this.firmValue;
      }
      // let res = await select(params);
      let res = await customerQuestionList(params);
      this.pageTotal = res.data.total;
      this.tableData = res.data.data;
      // this.tableLoad = false;
      // statusCode  合计金额
      console.log("返回参数", res.data);
      Message.success("已刷新数据");
    },
  },
};
</script>

<style lang="less" scoped>
/deep/.el-button--success,
.el-button--primary {
  color: #fff;
  background-color: #3278FE;
  border-color: #3278FE;

  &:hover {
    opacity: 0.7;
  }
}

#niche {
  .top-box {
    display: flex;
    align-items: center;
    height: 100%;
    flex-wrap: wrap;
    // line-height: 30px;
    margin-bottom: 40px;

    /deep/ .el-select {
      width: 100%;
    }

    .select-item {
      width: 20%;
      display: flex;
      align-items: center;
      margin-left: 10px;

      span {
        margin-right: 10px;
        min-width: 70px;
        text-align: right;
        align-items: center;
        font-size: 14px;
      }
    }
  }

  .search {
    display: flex;

    .left {
      width: 50%;
      display: flex;
      align-items: center;
      height: 100%;
      flex-wrap: wrap;
      line-height: 30px;

      .left-wrap {
        display: flex;
        align-items: center;
      }
    }

    .right {
      width: 50%;
      text-align: right;
      display: flex;
      /* justify-content: space-between; */
      justify-content: flex-end;
      align-items: center;
      height: 100%;
    }
  }

  /deep/ .el-table {
    // max-height: 555px;
    // padding-left: 50px;
    // box-sizing: border-box;
    // overflow-y: scroll;
  }

  /deep/.el-dialog__wrapper {
    .el-dialog {
      border-radius: 10px;
      box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);

      .el-select,
      .el-input {
        width: 100%;
      }

      .el-textarea__inner {
        min-height: 100px !important;
      }

      .el-dialog__header {
        text-align: center;

        .el-dialog__title {
          font-weight: bold;
          font-size: 20px;
        }
      }

      .el-dialog__body {
        position: relative;
        min-height: 500px;

        .el-form {}

        .el-form-item__content {

          // 修改样式会混乱
          .btn-box {
            text-align: right;
          }
        }
      }
    }
  }
}

main {
  .pagination {
    text-align: right;
    margin: 20px 0;
  }
}
</style>